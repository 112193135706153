<template>
  <div class="searchList">
    <div class="content">
      <div class="lbt">
        <img src="../assets/img/banner.jpg" />
      </div>
      <div class="sPage w1380">
        <div v-if="!isShowDetail">
          <div class="s-input">
            <el-row :gutter="20" type="flex" class="w1380" justify="flex-start">
              <el-col :span="3">
                <el-select v-model="typeVal" placeholder="">
                  <el-option
                    v-for="item in tablist"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="5">
                <el-select
                  v-model="columnVal"
                  multiple
                  collapse-tags
                  placeholder="请选择栏目"
                >
                  <el-option
                    v-for="item in columnList"
                    :key="item.uuid"
                    :label="item.name"
                    :value="item.uuid"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="14">
                <el-input
                  placeholder="请输入内容"
                  v-model="input3"
                  class="input-with-select"
                  @keyup.enter.native="getList()"
                >
                  <el-button
                    v-throttle
                    slot="append"
                    icon="el-icon-search"
                    @click="getList()"
                  ></el-button>
                </el-input>
              </el-col>
              <el-col :span="6">
                <el-date-picker
                  v-model="dateVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </div>
          <div class="s-wrap">
            <div class="s-content" v-loading="loading">
              <!-- <div class="search-tabs" style="justify-content: center">
                <ul>
                  <li
                    @click="changeTabs(index)"
                    v-for="(item, index) in tablist"
                    :key="index"
                    :id="item.id"
                    :class="cuIdx === index ? 'current' : ''"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div> -->
              <div class="s-center">
                <div
                  class="news-list-item"
                  v-for="(item, index) in tableList"
                  :key="index"
                  @click="toDetail(item)"
                >
                  <div class="list-date">
                    <p>
                      {{
                        $moment(item.publishTime)
                          .format("YYYY.MM.DD")
                          .split(".")[2]
                      }}
                    </p>
                    <p>
                      {{
                        $moment(item.publishTime)
                          .format("YYYY.MM.DD")
                          .slice(0, 7)
                      }}
                    </p>
                  </div>
                  <div class="list-title">
                    <div
                      class="li_bt ellipsis"
                      :title="item.title"
                      v-html="item.title"
                    ></div>
                    <p class="li_zy ellipsis" v-html="item.description"></p>
                  </div>
                </div>
              </div>
              <el-empty :image-size="200" v-if="!tableList.length"></el-empty>
            </div>
            <div class="s-page">
              <Pagination
                @pageChange="pageChange"
                v-if="tableList.length > 0"
                :totalNum="total"
                :currentPage="pageNum"
              ></Pagination>
            </div>
          </div>
        </div>
        <div class="text-detail" v-if="isShowDetail" style="margin-top: 6.25vw">
          <TextDetail :content="currentContent"></TextDetail>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextDetail from "./textDetail";
import Pagination from "../components/Pagination";
export default {
  components: {
    TextDetail,
    Pagination,
  },
  computed: {
    isShowDetail() {
      return this.$store.state.isShowDetail;
    },
    columnList() {
      return this.$store.state.columnList;
    },
  },

  data() {
    return {
      typeVal: "all",
      dateVal: "",
      columnVal: "",
      cuIdx: 0,
      input3: "",
      pageNum: 0,
      pageSize: 10,
      currentContent: null, //选中的数据的内容
      tablist: [
        { name: "全部", val: "all" },
        { name: "标题", val: "title" },
        { name: "正文", val: "txt" },
      ],
      tableList: [],
      total: 0,
      loading:false,
    };
  },
  created() {
    console.log(this.isShowDetail, "isShowDetail");
    this.getList();
  },
  methods: {
    // changeTabs(index) {
    //   // 0 1 2
    //   this.pageNum = 0;
    //   this.cuIdx = index;
    //   this.getList();
    // },
    toDetail(item) {
      this.currentContent = item;
      this.$store.commit("changeShowDetail", true);
    },
    changeDateType(val){
      if(!val) return;
     let s1 =  new Date(val).toLocaleDateString().replaceAll('/','-');
     let s2 = new Date(val).toLocaleTimeString();
     return s1+' ' +s2;
    },
    async getList() {
      console.log(this.dateVal, "dateVal");
      this.loading = true;
      const data = {
        pageNumber: this.pageNum,
        pageSize: this.pageSize,
        content: this.typeVal && this.typeVal == "txt"  ? this.input3 : "",
        title: this.typeVal && this.typeVal == "title" ? this.input3 : "",
        columnIds: this.columnVal,
        startDate:this.dateVal ? this.changeDateType(this.dateVal[0]):'',
        endDate: this.dateVal ? this.changeDateType(this.dateVal[1]):'',
        keywords:
          this.typeVal == 'all'? this.input3 : "",
        matchKeywords: "",
      };
      const res = await this.API.basic.getSearch(data);
      if (res) {
        this.tableList = res.data && res.data.content ? res.data.content : [];
        this.tableList.forEach((item) => {
          if (this.input3) {
            let reg = new RegExp(this.input3, "ig");
            item.title = item.title.replace(
              reg,
              `<span class='red-font'>${this.input3}</span>`
            );
            item.description = item.description.replace(
              reg,
              `<span class='red-font'>${this.input3}</span>`
            );
          }
        });
        this.total =  res.data && res.data.totalElements;
         this.loading = false;
      }
    },
    pageChange(val) {
      this.pageNum = val - 1;
      this.getList();
    },
  },
};
</script>
<style>
.el-date-editor .el-range-separator {
  width: auto;
}
.searchList .w1380 input {
  font-size: 16px;
}
.searchList .w1380 .el-icon-search {
  font-size: 24px;
}
.searchList .list-title .red-font {
  color: red;
}
</style>
<style lang="scss" scoped>
::v-deep a{
      color: #002bfd !important;
    }
.searchList {
  overflow-y: auto;
  background: #f5f5f5;
  padding-bottom: 50px;
  .content .lbt {
    position: relative;
  }
  .content .lbt img {
    width: 100%;
  }
  .w1380 {
    width: 1380px;
    margin: 0 auto;
  }
  .sPage {
    position: relative;
    margin-top: -80px;
    z-index: 1;
  }
  .search-tabs ul {
    display: flex;
    width: 100%;
    height: 90px;
  }
  .search-tabs ul li {
    height: 90px;
    line-height: 90px;
    text-align: center;
    font-size: 20px;
    width: 166px;
    color: #333;
    cursor: pointer;
  }
  .search-tabs ul li.current {
    background: #a12916;
    color: #fff;
    cursor: pointer;
  }
  .s-content {
    margin-top: 40px;
  }
  .s-wrap {
    background: #fff;
  }
  .news-list-item {
    height: 100px;
    border-top: 1px solid #ddd;
    background: #fff url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    padding-left: 50px;
    display: flex;
    align-items: center;
  }
  .news-list-item:first-of-type {
    border-top: none;
  }
  .news-list-item .list-date {
    width: 50px;
    text-align: center;
    color: #a12916;
    margin-right: 50px;
    font-size: 12px;
  }
  .news-list-item .list-date p:first-child {
    font-size: 34px;
  }
  .news-list-item .list-title {
    width: 730px;
    line-height: 180%;
  }
  .news-list-item .list-title .li_bt {
    height: 25px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .news-list-item .list-title .li_zy {
    height: 25px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
  }
  .news-list-item:hover {
    background: #a12916 url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    color: #fff;
    .list-date {
      color: #fff;
    }
    .list-title .li_bt {
      color: #fff;
    }
    .list-title .li_zy {
      color: #fff;
    }
  }
  .s-page {
    text-align: center;
    margin-top: 38px;
    padding-bottom: 65px;
  }
  ::v-deep .el-pager li.active {
    background-color: #a12916 !important;
    colre: #fff;
  }
  ::v-deep .el-pager li:hover {
    color: #a12916 !important;
  }
  ::v-deep .el-pagination button:hover {
    color: #a12916 !important;
  }
}
</style>
